import React from 'react';
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";

import '../components/lists/asset-list.scss';
import ToTop from '../components/to-top/to-top';

const FareShare = () => {
  return (
    <Layout>
      <div className="details case-study hc-template fareshare">
        <SEO title="Fareshare Capstone" />
        <div className="fixed-nav">
          <a className="home-link fixed-link" href="/">
            <img className="arrow-left" alt="arrow-left" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
            <span>home</span>
          </a>

          <a className="next-link fixed-link" href="/recipebook">
            <span>next</span>
            <img className="arrow-right" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
          </a>
        </div>
        <ToTop></ToTop>
        <section className="wrapper-container">
          <div className="row">
            <div className="col">
              <div className="vert-center">
              <h3>Promoting an environment for ride-sharing.</h3>
              <p>
                Designed to promote carpooling. With trust and transparency in mind.
              </p>
              <p><a rel="noopener noreferrer" href="https://www.figma.com/proto/HBW9JqfsuElibyebXUdDVV/Fareshare-for-Portfolio?page-id=1%3A2&node-id=55-407&viewport=216%2C40%2C0.06&t=jqQ1rJtGXMpV2Tj7-1&scaling=scale-down&content-scaling=fixed" title="Prototype link" target="_blank">prototype link</a></p>
              </div>
            </div>
            <div className="col hero-image">
              {/* <img alt="Final designs collage" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739356979/final-designs_wdakeb.png"></img> */}
            </div>
          </div>

        </section>
        <section className="wrapper-container image-container">
          {/* Hi-Fi Screens */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1742937012/fareshare-screens_q6oqwg.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1742937012/fareshare-screens_q6oqwg.png" ></img></a>
        </section>
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
              <h3 className="text-center">Discovery and empathy</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
              <p>
              A capstone project for Sprinboard’s UX Design certification, Fareshare is a mobile application conceptualized with the design thinking process. 
              </p>
              <p>
              In order to better understand the UX and associated hurdles to the carpooling process, the discovery process was kickstarted through market and competitive research.
              </p>
              <p>
              An initial survey was utilized to narrow down the focus to those planning to utilize the application to plan or find carpools for repeating trips. 
              </p>
              </div>
            </div>
          </div>
        </section>
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <div className="row mb-100">
                  
                    <h3>Competition</h3>
                    <p>Competitive and market research were also led by lessons learned from the founder of an application known as Ridejoy. </p>
                    <p>Opportunities missed from competitors, such as neglecting repeating trips or customers, minimalistic review systems, and a lack of personalization, led to inspiration.</p>
                
                </div>
                <div className="row justify-center align-center">
                  {/* Competitor Logos */}
                  <a className={`zoom-in logo ridejoy-logo`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108220/ridejoy_web_lg_1_y8sjqy.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108220/ridejoy_web_lg_1_y8sjqy.png" ></img></a>
                  <a className={`zoom-in logo blablacar-logo`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/BlaBlaCar_logo_1_vrshgp.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/BlaBlaCar_logo_1_vrshgp.png" ></img></a>
                  <a className={`zoom-in logo carworld-logo`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/carpool_map_1_jmboan.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/carpool_map_1_jmboan.png" ></img></a>
                </div>
              </div>
            </div>
          </div>
        </section>    
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                  Interviews and follow-up
                </h3>
                <p>
                Interviews were conducted and showed a consensus among potential users that a larger sense of trust, security, and transparency were needed for them to ride-share.
                </p>
                <p>
                Areas such as a public profile, messaging, verification, and user invites would all be considered.
                </p>
                <p>
                A follow-up survey, with the help of a Likert scale analysis, showed that specificity by time, clear feature differences of information on nearby carpools versus frequent locations, and reviews being emphasized should also be considered.
                </p>
              </div>

            </div>
          </div>
        </section> 
        <section className="wrapper-container image-container">
          {/* Affinity Map */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171563/Edy_Hwang_-_Capstone_1_Affinity_Map_ulmccl.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171563/Edy_Hwang_-_Capstone_1_Affinity_Map_ulmccl.png" ></img></a>
        </section>   
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3>
                  Personas and empathy maps
                </h3>
                <p>
                Personas and empathy maps were constructed based on two archetypes. 
                </p>
                <p>
                The occupant, chosen as they can be a rider or driver, and the planner, someone more inclined to plan repeating trips and invite their own colleagues or acquaintances. 
                </p>
              </div>
            </div>
          </div>
        </section>  
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
                <h3 className="text-center">Ideate and implement</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
                <p>
                Mobile was chosen as the format, differentiating itself from popular ride-sharing resources in the U.S like CarpoolWorld and Craigslist. This was largely due to the popularity of Uber/Lyft and the hopes of having an integrated chat feature.
                </p>
                <p>
                Quick sketches on paper initiated the ideation phase accompanied by the gathering of inspirational material.
                </p>
              </div>
            </div>
          </div>
        </section>   
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
              <h3>
                Red routes, user flows, wireframes
              </h3>
              <p>
              Red routes were the primary driving force behind design priority, with user flows and wireframes reflecting the process for creating and finding a carpool. 
              </p>
              <p>
              Additional screens such as “Social”, for messaging and profile views, and “My Trips” were included.
              </p>
              </div>
            </div>
          </div>
        </section>   
        <section className="wrapper-container image-container">
          {/* Wireframes */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171935/Wireframe_Designs-np_vwijm3.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722171935/Wireframe_Designs-np_vwijm3.png" ></img></a>
        </section>
        <section className="wrapper-container content-container">
          <div className="row min-height">
            <div className="col">
              <div className="vert-center">
              <h3 className="text-center">Visual identity</h3>
              </div>
            </div>
            <div className="col padding-l-zero">
              <div className="vert-center">
              <p>
              The visual identity of the application was constructed through a mood board and style guide. Shades of green were utilized to appeal to both its usage in driving environments and for its use to promote natural/health benefits. 
              </p>
              <p>
              A hero image was chosen for the landing screen as it could be seen as more personal while also offering a contrasting background to any material pulled up the screen.
              </p>
              </div>
            </div>
          </div>
        </section>  
        <section className="wrapper-container image-container">
          {/* Mood board */}
          <a className={`zoom-in asset-xlarge`} href="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172084/Moodboard-np_qjxryw.png" rel="noopener noreferrer" target="_blank"><img src="https://res.cloudinary.com/dkcdueneq/image/upload/v1722172084/Moodboard-np_qjxryw.png" ></img></a>
        </section>
        <section className="wrapper-container content-container">
          <div className="row min-height height-extra">
            <div className="col">
              <div className="vert-center">
              <h3>
                Test and iterate
              </h3>
              <p>
              Two rounds of usability tests and updates were performed. Major insights could be categorized into three categories: information and details, access to actions, and contrast and compatibility. Some examples include the following.
              </p>
              <p>
              Users exhibited a need for more information as a carpooling service was something they were new to.
              </p>
              <p>
              Users quickly searched a list prior to filtering, resulting in actions being hidden.
              </p>
              <p>
              In-person testing showed the need to accommodate varying display types through an increased use of contrast.
              </p>
              </div>
            </div>
          </div>
        </section> 
        <div className="project-bottom">
          <Link to="/recipebook" title="To cooking web app">
            <span>a cooking web app</span>
            <img className="arrow-right-long" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/arrow-right-long-light_1_qchclz.png"></img>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default FareShare;